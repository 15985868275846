<template>
  <div class="page">
    <LandingHeader/>
    <section id="guest" class="guest">
      <div class="container">
        <div class="guest__inner row" style="justify-content: center;">
          <h2 class="title title_guest" style="margin:0 0 30px 0;text-align:center">{{ $t("landing.guest.title") }}</h2>
          <div style="display:flex;width:100%;margin:0 auto 30px auto;flex-wrap:wrap;" :style="`flex-direction:${isMobile?'column':'row'}`">
            <div style="display:flex;flex-direction:column;align-items:center;padding:20px;" :style="`width:${isMobile?'100%':'25%'}`">
              <div style="max-width:80%">
                <img src="https://cdn.airguideapp.com/images/app-circle-pdfs.png" alt="">
              </div>
              <div>
                <p class="airguide_h3">Upload PDFs to explain the tricky stuff

                </p>
                <p class="airguide_p">Provide step-by-step instructions or operating manuals when that extra bit of detail is required.
                </p>
              </div>
            </div>
            <div style="display:flex;flex-direction:column;align-items:center;padding:20px;" :style="`width:${isMobile?'100%':'25%'}`">
              <div style="max-width:80%;">
                <img src="https://cdn.airguideapp.com/images/app-circle-weblinks.png" alt="">
              </div>
              <div>
                <p class="airguide_h3">Top questions and highlights
                </p>
                <p class="airguide_p">What time is checkout? Add frequently asked questions to your homepage so guests won’t miss them.
                </p>
              </div>
            </div>
            <div style="display:flex;flex-direction:column;align-items:center;padding:20px;" :style="`width:${isMobile?'100%':'25%'}`">
              <div style="max-width:80%;">
                <img src="https://cdn.airguideapp.com/images/app-circle-highlights.png" alt="">
              </div>
              <div>
                <p class="airguide_h3">Link to websites and directions
                </p>
                <p class="airguide_p">Help your guests explore the local area with convenient links and directions to local attractions.
                </p>
              </div>
            </div>
            <div style="display:flex;flex-direction:column;align-items:center;padding:20px;" :style="`width:${isMobile?'100%':'25%'}`">
              <div style="max-width:80%;">
                <img src="https://cdn.airguideapp.com/images/app-circle-highlights.png" alt="">
              </div>
              <div>
                <p class="airguide_h3">Link to websites and directions
                </p>
                <p class="airguide_p">Help your guests explore the local area with convenient links and directions to local attractions.
                </p>
              </div>
            </div>
          </div>
          <div style="display:flex;width:100%;margin:0 auto;flex-wrap:wrap;" :style="`flex-direction:${isMobile?'column':'row'}`">
            <div style="display:flex;flex-direction:column;align-items:center;padding:20px;" :style="`width:${isMobile?'100%':'25%'}`">
              <div style="max-width:80%;">
                <img src="https://airguideapp.com/images/app-circle-message.png" alt="">
              </div>
              <div>
                <p class="airguide_h3">Written descriptions to cover the basics
                </p>
                <p class="airguide_p">Written descriptions to cover the basics
                </p>
              </div>
            </div>
            <div style="display:flex;flex-direction:column;align-items:center;padding:20px;" :style="`width:${isMobile?'100%':'25%'}`">
              <div style="max-width:80%;">
                <img src="https://cdn.airguideapp.com/images/app-circle-text-descriptions.png" alt="">
              </div>
              <div>
                <p class="airguide_h3">Unlimited images to personalise your app
                </p>
                <p class="airguide_p">Bring your airguide™ to life by uploading your own images.
                </p>
              </div>
            </div>
            <div style="display:flex;flex-direction:column;align-items:center;padding:20px;" :style="`width:${isMobile?'100%':'25%'}`">
              <div style="max-width:80%;">
                <img src="https://cdn.airguideapp.com/images/app-circle-upload-video.png" alt="">
              </div>
              <div>
                <p class="airguide_h3">Video uploads straight from your phone
                </p>
                <p class="airguide_p">Shoot videos with your phone and upload them straight away.
                </p>
              </div>
            </div>
            <div style="display:flex;flex-direction:column;align-items:center;padding:20px;" :style="`width:${isMobile?'100%':'25%'}`">
              <div style="max-width:80%;">
                <img src="https://cdn.airguideapp.com/images/app-circle-highlights.png" alt="">
              </div>
              <div>
                <p class="airguide_h3">Link to websites and directions
                </p>
                <p class="airguide_p">Help your guests explore the local area with convenient links and directions to local attractions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="appeal">
      <div class="container">
        <div class="appeal__inner row">
          <div class="appeal__info">
            <h2
                class="title appeal_title"
                v-html="$t('landing.appeal.title')"
            ></h2>
            <ul class="appeal__list">
              <li class="appeal__item appeal__item_1">
                {{ $t("landing.appeal.item_1") }}
              </li>
              <li class="appeal__item appeal__item_2">
                {{ $t("landing.appeal.item_2") }}
              </li>
              <li class="appeal__item appeal__item_3">
                {{ $t("landing.appeal.item_3") }}
              </li>
            </ul>
          </div>

          <div class="appeal__decor">
            <div class="appeal__sticker">
              <img
                  src="@/assets/landing/img/appeal_sticker.png"
                  alt="Sticker"
              />
            </div>
            <div class="appeal__cloud">
              <p class="hint__text">{{ $t("landing.appeal.hint") }}</p>
            </div>
          </div>
        </div>
      </div>

    </section>
    <section class="feedback">
      <div class="container">
        <div class="feedback__heading">
          <h2 class="title title_feedback">
            {{ $t("landing.feedback.title") }}
          </h2>
          <p class="feedback__text">{{ $t("landing.feedback.text") }}</p>
        </div>
        <div class="feedback__inner row">
          <div class="feedback__item feedback__item_sad">
            <div class="feedback__img feedback__img_sad"></div>
            <span class="feedback__caption feedback__caption_sad">{{
                $t("landing.feedback.caption_1")
              }}</span>
            <p class="feedback__descr">
              {{ $t("landing.feedback.descr_1") }}
            </p>
            <svg
                class="feedback__minus"
                xmlns="http://www.w3.org/2000/svg"
                width="49"
                height="9"
                viewBox="0 0 49 9"
            >
              <g>
                <g>
                  <path
                      fill="#ff5416"
                      d="M4 9a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h41a4 4 0 0 1 4 4v1a4 4 0 0 1-4 4z"
                  />
                </g>
              </g>
            </svg>
          </div>

          <div class="feedback__types">
            <span>{{ $t("landing.feedback.subtitle") }}</span>
          </div>

          <div class="feedback__item feedback__item_smile">
            <div class="feedback__img feedback__img_smile"></div>
            <span class="feedback__caption feedback__caption_smile">{{
                $t("landing.feedback.caption_2")
              }}</span>
            <p class="feedback__descr">
              {{ $t("landing.feedback.descr_2") }}
            </p>
            <svg
                class="feedback__plus"
                xmlns="http://www.w3.org/2000/svg"
                width="49"
                height="50"
                viewBox="0 0 49 50"
            >
              <g>
                <g>
                  <path
                      fill="#3fa535"
                      d="M4 29a4 4 0 0 1-4-4v-1a4 4 0 0 1 4-4h41a4 4 0 0 1 4 4v1a4 4 0 0 1-4 4z"
                  />
                </g>
                <g>
                  <path
                      fill="#3fa535"
                      d="M19.643 4a4 4 0 0 1 4-4h1.248a4 4 0 0 1 4 4v41.319a4 4 0 0 1-4 4h-1.248a4 4 0 0 1-4-4z"
                  />
                </g>
              </g>
            </svg>
          </div>

          <svg
              class="feedback__line"
              xmlns="http://www.w3.org/2000/svg"
              width="974.707"
              height="97.961"
              viewBox="0 0 974.707 97.961"
          >
            <g transform="translate(-476 -11044)">
              <path
                  class="feedback__line_4"
                  d="M5420,11989.961v-91.834"
                  transform="translate(-3980 -849)"
                  fill="none"
                  stroke="#3fa535"
                  stroke-width="2"
              />
              <path
                  class="feedback__line_5"
                  d="M5,15,15,5,25,15"
                  transform="translate(1425 11043)"
                  fill="none"
                  stroke="#3fa535"
                  stroke-linejoin="round"
                  stroke-width="2"
              />
              <path
                  class="feedback__line_3"
                  d="M4460,11989.961h960"
                  transform="translate(-3980 -849)"
                  fill="none"
                  stroke="#3fa535"
                  stroke-width="2"
              />
              <path
                  class="feedback__line_2"
                  d="M4460,11898.127v91.834"
                  transform="translate(-3980 -849)"
                  fill="none"
                  stroke="#3fa535"
                  stroke-width="2"
              />
              <line
                  class="feedback__line_1"
                  y2="1"
                  transform="translate(480 11048)"
                  fill="none"
                  stroke="#3fa535"
                  stroke-linecap="round"
                  stroke-width="8"
              />
            </g>
          </svg>
        </div>
        <div class="feedback__sponsors">
          <img src="@/assets/landing/img/feedback_brands.png" alt="" />
        </div>
      </div>
    </section>
    <section class="social">
      <div class="container">
        <div class="social__inner row">
          <div class="social__decor">
            <div class="social__circle">
              <div class="social__cloud">
                    <span
                        class="social__caption"
                        v-html="$t('landing.social.caption')"
                    ></span>
              </div>
              <div class="social__icon social__icon_1">
                <img src="@/assets/landing/img/icons/social_1.png" alt="" />
              </div>
              <div class="social__icon social__icon_2">
                <img src="@/assets/landing/img/icons/social_2.png" alt="" />
              </div>
              <div class="social__icon social__icon_3">
                <img src="@/assets/landing/img/icons/social_3.png" alt="" />
              </div>
              <div class="social__icon social__icon_4">
                <img src="@/assets/landing/img/icons/social_4.png" alt="" />
              </div>
              <div class="social__icon social__icon_5">
                <img src="@/assets/landing/img/icons/social_5.png" alt="" />
              </div>
              <div class="social__icon social__icon_6">
                <img src="@/assets/landing/img/icons/social_6.png" alt="" />
              </div>
            </div>
          </div>
          <div class="social__info">
            <h2 class="title title_social">
              {{ $t("landing.social.title") }}
            </h2>
            <p class="social__text">{{ $t("landing.social.text") }}</p>
            <p class="social__descr">Add link on them for «Sharing» information about your accommodation on social networks are met.</p>
            <!--                <ul class="social__list">-->
            <!--                  <li class="social__item">-->
            <!--                    <span class="social__num">01</span>-->
            <!--                    <div v-html="$t('landing.social.item_1')"></div>-->
            <!--                  </li>-->
            <!--                  <li class="social__item">-->
            <!--                    <span class="social__num">02</span>-->
            <!--                    <div v-html="$t('landing.social.item_2')"></div>-->
            <!--                  </li>-->
            <!--                  <li class="social__item">-->
            <!--                    <span class="social__num">03</span>-->
            <!--                    <div v-html="$t('landing.social.item_3')"></div>-->
            <!--                  </li>-->
            <!--                </ul>-->
          </div>
        </div>
      </div>
    </section>
    <LandingFooter/>
</div>
</template>
<script lang="js">
import $ from "jquery";
import bodymovin from "lottie-web";

export default {
  components: {
    LandingHeader:() => import("@/components/Landing/LandingHeader"),
    LandingFooter:() => import("@/components/Landing/LandingFooter")
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  data(){
    return{
      isMobile:false,
    }
  },
  methods:{
    lottie() {
      var sad = document.querySelector(".feedback__img_sad");
      var smile = document.querySelector(".feedback__img_smile");
      var cool = document.querySelector(".place__emoji_cool");
      var stars = document.querySelector(".place__emoji_stars");

      bodymovin.loadAnimation({
        wrapper: sad,
        animType: "svg",
        loop: true,
        animationData: require("@/assets/landing/img/icons/sad.json"),
      });

      bodymovin.loadAnimation({
        wrapper: smile,
        animType: "svg",
        loop: true,
        animationData: require("@/assets/landing/img/icons/smile.json"),
      });

      bodymovin.loadAnimation({
        wrapper: cool,
        animType: "svg",
        loop: true,
        animationData: require("@/assets/landing/img/icons/cool.json"),
      });

      bodymovin.loadAnimation({
        wrapper: stars,
        animType: "svg",
        loop: true,
        animationData: require("@/assets/landing/img/icons/stars.json"),
      });
    },
    animation() {
      var item = $(".feedback__line");

      var windowHeight = $(window).height();
      var windowTop = $(window).scrollTop();
      var windowBottom = windowTop + windowHeight;

      item.each(function() {
        var elementTop = $(this).offset().top;

        if (elementTop <= windowBottom) {
          $(".feedback__line_1").addClass("active");
          $(".feedback__line_2").addClass("active");
          $(".feedback__line_3").addClass("active");
          $(".feedback__line_4").addClass("active");
          $(".feedback__line_5").addClass("active");
        } else {
          $(".feedback__line_1").removeClass("active");
          $(".feedback__line_2").removeClass("active");
          $(".feedback__line_3").removeClass("active");
          $(".feedback__line_4").removeClass("active");
          $(".feedback__line_5").removeClass("active");
        }
      });
    },
  },
  mounted() {
    document.title = 'Products';
    this.animation();
    this.lottie();
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper){
  background-color: #fff;
}
</style>
<style lang="scss">
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 40px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

span,
time,
label {
  display: inline-block;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1.5;
}

button {
  cursor: pointer;
}
@import "@/scss/landing/style.scss";
</style>
